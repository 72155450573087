
<template>
<div>
  <CCard>
    <CCardHeader>
            <strong>Tasks</strong>
          </CCardHeader>
    <CCardBody>
      <CDataTable
        :items="items"
        :fields="fields"
         striped
      clickable-rows
        column-filter
        table-filter
        items-per-page-select
        :items-per-page="20"
        sorter
        ref="vuetable"
        pagination
      >
        <template #taskStatus-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'taskStatus',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="0">In Complete</option>
            <option value="1">Required Follow Up</option>
            <option value="2">Approved</option>
             <option value="3">Pending Approval</option>
          </select>
        </template> 

      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
 


     <template #firstName="{item}">
        <td class='font-weight-bold'
          v-if="item.firstName && item.lastName"
        >{{ item.firstName }} {{item.lastName }}</td>
        <td v-else>--</td>
     </template>

      
       <template #lastName="{item}">
        <td class='font-weight-bold'
          v-if="item.lastName"
        >{{ item.lastName }}</td>
        <td v-else>--</td>
     </template>
     <template #craneModel="{item}">
        <td class='font-weight-bold'
          v-if="item.craneModel"
        >{{ item.craneModel }}</td>
        <td v-else>--</td>
     </template>
     <template #jobNo="{item}">
        <td class='font-weight-bold'
          v-if="item.jobNo"
        >{{ item.jobNo }}</td>
        <td v-else>--</td>
     </template>
     <template #craneNo="{item}">
        <td class='font-weight-bold'
          v-if="item.craneNo"
        >{{ item.craneNo }}</td>
        <td v-else>--</td>
     </template>
     <template #workDescription="{item}">
        <td class='font-weight-bold'
          v-if="item.workDescription"
        >{{ item.workDescription }}</td>
        <td v-else>--</td>
     </template>
     <template #zpmcValue="{item}">
        <td class='font-weight-bold'
          v-if="item.zpmcValue"
        >{{ item.zpmcValue }}</td>
        <td v-else>--</td>
     </template>
     <template #typeOfBreak="{item}">
        <td class='font-weight-bold'
          v-if="item.typeOfBreak"
        >{{ item.typeOfBreak }}</td>
        <td v-else>--</td>
     </template>
      <template #followUpDesc="{item}">
        <td class='font-weight-bold'
          v-if="item.followUpDesc"
        >{{ item.followUpDesc }}</td>
        <td v-else>--</td>
     </template>
      <template #lastSubmittedby="{item}">
        <td class='font-weight-bold'
          v-if="item.lastSubmittedby"
        >{{ item.lastSubmittedby }}</td>
        <td v-else>--</td>
     </template>
     

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     <template #startDate="{item}">
        <td class='font-weight-bold'
          v-if="item.startDate"
        >{{ item.startDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>

      <template #endDate="{item}">
        <td class='font-weight-bold'
          v-if="item.endDate"
        >{{ item.endDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>

       

        <template #time="{ item }">
          <td class='font-weight-bold' v-if="item.time">
            {{ item.time | dateParse("HH.mm") | dateFormat("hh:mm A ") }}
          </td>
          <td v-else>--</td>
        </template>
        
         <template #taskStatus="{ item }">
          <td class='font-weight-bold' v-show="item.taskStatus == 0">Not Started</td>
          <td class='font-weight-bold' v-show="item.taskStatus == 1">Required Follow Up</td>
          <td class='font-weight-bold' v-show="item.taskStatus == 2">Approved</td>
           <td class='font-weight-bold' v-show="item.taskStatus == 3">Pending Approval</td>
           
        </template>
        <template #show_details="{ item }">
          <td class="py-2">
            
            <CDropdown togglerText="Actions" color="info">
              
              <CDropdownItem
                :to="{
                  name: 'Task Items',
                  params: { jobTaskId: item.jobTaskId,jobId:item.jobId, mode: 'view' },
                }"
                >Task Items
              </CDropdownItem>

               <CDropdownItem
                :to="{
                  name: 'TaskSubmittdUsers',
                  params: { jobTaskId: item.jobTaskId },
                }"
                >Task Submitted Users
              </CDropdownItem>
       <!-- <CDropdownItem
               
                 @click="toggleDetails(item.jobTaskId)"
                >Task Items
              </CDropdownItem> -->
            
            </CDropdown>
          </td>
        </template>

      
      </CDataTable>
    </CCardBody>
    <CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
  </CCard>
   <!-- <CCard
   :show.sync="cardModal"
   >
    <CCardBody>
    <CDataTable>
      
  </CDataTable>
    </CCardBody>
    <CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
  </CCard>  -->
</div>
</template>

<script>
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
const fields = [
   { key: 'index',_style: "min-width:100px",_classes: 'font-weight-bold', },
  
{ key: "taskName",label: "Task Name",  _style: "min-width:200px",_classes: 'font-weight-bold' },

//  { key: "lastSubmittedby",label: "Last Submitted by", _classes: 'font-weight-bold', _style: "min-width:200px" },

 {
    key: "createdTime",
    label: "Created Date & Time",_classes: 'font-weight-bold',
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
export default {
  name: "JobTasks",
   components: {
    ConfirmationModal
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      cardModal:false,
    };
  },
  methods: {
    getBriefings() {
      const url = process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/jobs/tasks/"+this.$route.params.jobId;
      console.log("getBriefings", url);
       this.apiGetSecure(
        url
      )
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
 cancel() {
      this.$router.push({ name: 'Jobs' })
    },
 toggleDetails(jobTaskId){
   
  const url = process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/jobs/tasks/Items/"+jobTaskId
      console.log("getBriefings", url);
      this.cardModal=true;
       this.apiGetSecure(
        url
      )
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },

    // deleteFaq(briefingId) {
    //   console.log("delete called with", this.briefingId);
    //   this.apiGetSecure(
    //     process.env.VUE_APP_API_HOST +"/emapp/web/secure/delete/subgroup/"+this.briefingId)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getSymptoms();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //       // window.location.reload();
    //     });
    // },
    // navToNewSymptoms() {
    //   this.$router.push({ name: "NewBriefing", params: { mode: "new" } });
    // },
  },
  mounted() {
    this.getBriefings();
  },
};
</script>